@import "../../assets/scss/variable.scss";

/*----------------------------------------------------*/
/* Image Gallery */
/*----------------------------------------------------*/

.play_button{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.gallery-item {
    position: relative;
    // overflow: hidden;
    
}
.image-gallery .swiper-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-item .gallery-image img {
    cursor: pointer;
    transform: scale(1);
    transition: $transition-3;
    
}

.gallery-item .gallery-image .img-fluid, .video-popup .img-fluid {
    height: 303px;
    object-fit: cover;
    // object-fit: fill;
}

.gallery-item:hover .gallery-image img {
    transform: scale(1.1);
}

.gallery-item .gallery-overlay {
    // display: flex;
    // flex-wrap: wrap;
    background-color: #ffffff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    border-radius: $radius-5;
    transition: $transition-3;
}
.gallery-item .gallery-overlay .open-btn {
    align-self: flex-start;
    border: none;
    padding: 0;
    background: none;
    font-size: 20px;
    color: $primary-color;
}
.gallery-item .gallery-overlay .overlay-text {
    color: #ffffff;
    align-self: flex-start;
    margin: auto !important;
    width: 90%;
    padding: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

@media only screen and (max-width:768px) {
    .gallery-item .gallery-overlay .overlay-text{
        font-size: 18px;
    }
}

@media only screen and (max-width:600px) {
    .gallery-item .gallery-overlay .overlay-text{
        font-size: 16px;
    }
}
.gallery-item .gallery-overlay .gallery-info {
    // align-self: flex-end;
    width: 100%;
}
.gallery-item .gallery-info .gallery-title {
    color: $title-color;
    margin-bottom: 7px;
    transition: $transition-3;
}
.gallery-item .gallery-info .gallery-category {
    color: $primary-color;
}

.gallery-item:hover .gallery-overlay {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

/*------------------------------*/
/* gallery : Style 2 */
/*------------------------------*/
.gallery-item.gallery-style-2 .gallery-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    transform: none;
    padding: 0;
    cursor: pointer;
}
.gallery-item.gallery-style-2 .gallery-overlay .open-btn {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    background: $primary-color;
    color: $white-color;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    opacity: 0;
    margin-top: 25px;
    transition: $transition-3;
}
.gallery-item.gallery-style-2:hover .gallery-overlay .open-btn {
    opacity: 1;
    margin-top: 0;
}

/*------------------------------*/
/* Lightbox PopUp Setting */
/*------------------------------*/
.ReactModalPortal .ril-outer .ril-toolbar .ril__toolbarSide .ril-zoom-in,
.ReactModalPortal .ril-outer .ril-toolbar .ril__toolbarSide .ril-zoom-out {
    display: none;
}
.ReactModalPortal .ril-outer .ril-prev-button {
    left: 25px;
    background-size: 13px;
    width: 40px;
    height: 60px;
    display: flex;
    padding: 0;
    background-color: rgba(255,255,255,0.1);
}
.ReactModalPortal .ril-outer .ril-next-button {
    right: 25px;
    background-size: 13px;
    width: 40px;
    height: 60px;
    display: flex;
    padding: 0;
    background-color: rgba(255,255,255,0.1);
}