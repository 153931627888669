/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-rubik{
  font-family: 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-archivo{
  font-family: 'Roboto';
}


.preloader{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader img {
  max-width: 95% !important;
}