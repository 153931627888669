@import "../../assets/scss/variable.scss";

/*----------------------------------------------------*/
/* Blog Elements */
/*----------------------------------------------------*/
.blog-wrapper.grid-wrapper {
    grid-gap: 40px 20px;
}

.blog-post{
    padding: 1px 5px;
    border-radius: 5px;
    box-shadow: 0 20px 25px -5px rgba(131, 131, 131, 0.1), 0 8px 10px -6px rgb(131, 131, 131, 0.1);
}

.compete-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0px;
    text-align: center;
    & a{
        width: 300px;
        & div{
            text-align: center;
            width: 100%;
            padding: 3px 0;
        }
    }
}


.blog-post .entry-content{
    margin: 4px 8px !important;
    font-family: 'Rubik';
}
.blog-post .entry-content .entry-description img{
    max-width: 95%;
    height: auto!important;
}
.education-image{
    // width: 100%; 
    text-align: center;
    position: relative;
}

.education-image img{
    
    object-fit: contain;
}

.education-image svg{
    position: absolute;
    top: 0px;
    right: -2px;
    overflow: hidden;
    height: 101%;
    color: white;
    @media only screen and (max-width:991px) {
        display: inline-block;
    }
   }
   

.blog-post .post-image {
    position: relative;
    overflow: hidden;
    border-radius:  $radius-5;
    width: 70% !important;

    margin: 25px auto;
}

.blog-post .post-image img {
    max-width: 100%;
    height: 350px;
    object-fit: contain;
}

.blog-elements{
    padding: 0 !important;
}

.blog-elements .entry-content{
    padding: 10px ;
}

.blog-elements .post-image{
    width: 100% !important;
    margin: 0 !important;
}

.blog-post .entry-title {
    line-height: normal;
}
.blog-post .entry-title a {
    color: $title-color;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-decoration: none;
    transition: $transition;
}
.blog-post .entry-title a:hover {
    color: $primary-color;
}

.blog-post .entry-meta ul {
    display: flex;
    align-items: center;    
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 10px;
}
.blog-post .entry-meta li a {
    font-size: 14px;
    font-weight: 500;
}
.blog-post .entry-meta .entry-meta-category a {
    position: relative;
    text-decoration: none;
    padding: 3px 15px;
    display: inline-block;
    line-height: 18px;
    color: $primary-color;
    font-weight: 700;
    z-index: 1;
}
.blog-post .entry-meta .entry-meta-category a:before {
    content: "";
    width: 30px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f6f6f6;
    z-index: -1;
    opacity: 0.8;
    border-radius:  $radius-50;
    transition: $transition;
}
.blog-post .entry-meta .entry-meta-category a:hover::before {
    width: 100%;
}

.blog-post .entry-meta .entry-meta-date a, .blog-post .entry-meta .entry-meta-date div{
    text-decoration: none;
    display: inline-block;
    line-height: 18px;
    color: #575757;
    text-transform: capitalize;
}
.blog-post .blog-image + .entry-description {
    margin-top: 30px;
}


.blog-post{
    padding: 15px;
}



.blog-post .entry-description div {
    margin-bottom: 0;
    // text-align: justify;
    color: #000;
    font-size: 16px;
    white-space: pre-wrap; 
}

.blog-post .entry-button{
    margin: 20px 0;
    width: 105px;
    display: flex;
}

.blog-post .entry-button-link{
    width: 100%;
    padding: 2px 0;
    color: #00843D;
    border-radius: 5px;
    border: 1px solid #00843D;
    font-weight: 700;
    text-align: center;
}

/*----------------------------------------------------*/
/* Blog Page Type */
/*----------------------------------------------------*/
.sticky-post .post-image:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 45px 45px 0;
    border-color: transparent $primary-color transparent transparent;
}
.sticky-post .post-image:before {
    content: "\f08d";
    position: absolute;
    top: 9px;
    right: 8px;
    z-index: 2;
    font-family: "Font Awesome 5 free";
    color: $white-color;
    font-size: 13px;
    font-weight: 900;
    line-height: normal;
    transform: rotate(45deg);
}

/*----------------------------------------------------*/
/* 1st Blog Post Large */
/*----------------------------------------------------*/
.grid-wrapper.blog-post-large {
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(3,auto);
}
.grid-wrapper.blog-post-large .blog-post:first-child {
    grid-column: 1 / 4;
    grid-row: 1 / 3;
}
.grid-wrapper.blog-post-large,
.blog-wrapper.blog-single-col {
    display: grid;
    grid-gap: 25px 25px;
}

/*----------------------------------------------------*/
/* Blog Page Article */
/*----------------------------------------------------*/
article.blog-post .entry-title a {
    font-size: 22px;
    line-height: 30px;
}

/*----------------------------------------------------*/
/* Post Navigation */
/*----------------------------------------------------*/
.post-navigation {
    display: flex;
    gap: 10px 30px;
    margin: 50px 0;
    padding: 40px 0;
    justify-content: space-between;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}
.post-navigation > div {
    flex: 0 0 calc(50% - 30px);
}
.post-navigation a {
    display: inline-flex;
    align-items: center;
    padding: 18px 30px;
}
.post-navigation a span {
    display: inline-block;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.post-navigation .nav-previous a:before {
    left: auto;
    right: 0;
}
.post-navigation .nav-next {
    text-align: right;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {

    /* Post Navigation */
    .post-navigation a span {width: 250px;}
}

@media only screen and (max-width: 767px) {

    /* Post Navigation */
    .post-navigation a span {width: 150px;}
}

@media only screen and (max-width: 575px) {

    /* Post Navigation */
    .post-navigation {flex-wrap: wrap; justify-content: center;}
    .post-navigation > div {flex: inherit}
    .post-navigation a span {width: 200px;}
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide a {
    color: black !important;
  }

  .swiper-wrapper .swiper-slide .swiper-button-prev {
    background-color: red !important;
  }
  
  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 1;
  }
  