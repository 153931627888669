/*=====================================================*/
/* General - CSS */
/*=====================================================*/
.custom-sticky {
  top: 125px;
  z-index: 1;
}
.color-inherit,
.color-inherit:hover {
  color: inherit;
}
.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}

.uppercase {
  text-transform: uppercase;
}

/*=====================================================*/
/* Border Radius */
/*=====================================================*/
.border-radius {
  border-radius: $radius-3;
}
.border-radius-5 {
  border-radius: $radius-5;
}
.border-radius-7 {
  border-radius: $radius-7;
}
.border-radius-12 {
  border-radius: $radius-12;
}
.border-radius-30 {
  border-radius: $radius-30;
}
.border-radius-50 {
  border-radius: $radius-50;
}
.border-radius-80 {
  border-radius: $radius-80;
}
.border {
  border-color: $border-color !important;
}

/*=====================================================*/
/* Text Color */
/*=====================================================*/
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-dark {
  color: $dark-color !important;
}
.text-light {
  color: $light-color !important;
}
.text-white {
  color: $white-color !important;
}

/*=====================================================*/
/* Background Color */
/*=====================================================*/
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-dark {
  background-color: $dark-color !important;
}
.bg-light {
  background-color: $light-color !important;
}
.bg-white {
  background-color: $white-color !important;
}

/*=====================================================*/
/* Background Overlay Color */
/*=====================================================*/
.bg-overlay-primary,
.bg-overlay-secondary,
.bg-overlay-black,
.bg-overlay-white {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bg-overlay-primary:before {
  background-color: $primary-color;
}
// .bg-overlay-secondary:before {background-color: $secondary-color;}
.bg-overlay-black:before {
  background-color: $dark-color;
}
.bg-overlay-white:before {
  background-color: $white-color;
}

.bg-overlay-primary:before,
.bg-overlay-secondary:before,
.bg-overlay-black:before,
.bg-overlay-white:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.9;
}
.overlay-10:before {
  opacity: 0.1;
}
.overlay-20:before {
  opacity: 0.2;
}
.overlay-30:before {
  opacity: 0.3;
}
.overlay-40:before {
  opacity: 0.4;
}
.overlay-50:before {
  opacity: 0.5;
}
.overlay-60:before {
  opacity: 0.6;
}
.overlay-70:before {
  opacity: 0.7;
}
.overlay-80:before {
  opacity: 0.8;
}
.overlay-90:before {
  opacity: 0.9;
}

/*=====================================================*/
/* Swiper Slider */
/*=====================================================*/
.swiper .swiper-pagination {
  position: relative;
  margin-top: 40px;
}
.swiper .swiper-pagination .swiper-pagination-bullet {
  background: $secondary-color;
  width: 8px;
  height: 8px;
  opacity: 0.5;
}
.swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: $primary-color;
  opacity: 1;
}

/* Swiper Custom Arrow */
.swiper .swiper-button-prev:after,
.swiper .swiper-button-next:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  color: $secondary-color;
  opacity: 0.6;
  transition: $transition;
  padding: 10px 10px;
  border-radius: 50%;
  background-color: #fff;
}
.swiper .swiper-button-prev:after {
  content: '\f060';
}
.swiper .swiper-button-next:after {
  content: '\f061';
}
.swiper .swiper-button-prev:hover:after,
.swiper .swiper-button-next:hover:after {
  opacity: 1;
}

/* Swiper Arrow Light */
.swiper.swiper-slider-light .swiper-button-prev:after,
.swiper.swiper-slider-light .swiper-button-next:after {
  color: $white-color;
}

/*=====================================================*/
/* List Item */
/*=====================================================*/
.list-item {
  display: grid;
  grid-gap: 5px 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-item li {
  font-size: 15px;
  line-height: 28px;
  color: $dark-color;
}

/*=====================================================*/
/* COUNTER COMPONENT */
/*=====================================================*/

.counter {
  display: flex;
  justify-content: center;
}

.counter-component {
  background-color: #00843d;
}

.counter-header {
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 5rem;
  line-height: 40px;
}

.counter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-inner {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.counter .counter-content .counter-info {
  font-size: 48px !important;
}

.counter-content .counter-info {
  justify-content: center;
}

/*=====================================================*/
/* Section Half-BG */
/*=====================================================*/
.bg-primary-half,
.bg-secondary-half,
.bg-light-half {
  position: relative;
}
.bg-primary-half:before,
.bg-secondary-half:before,
.bg-light-half:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 470px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bg-primary-half:before {
  background: $primary-color;
}
.bg-secondary-half:before {
  background: $secondary-color;
}
.bg-light-half:before {
  background: $light-color;
}

[data-bg-height='200']:before {
  height: 200px;
}
[data-bg-height='250']:before {
  height: 250px;
}
[data-bg-height='300']:before {
  height: 300px;
}
[data-bg-height='350']:before {
  height: 350px;
}
[data-bg-height='400']:before {
  height: 400px;
}
[data-bg-height='450']:before {
  height: 450px;
}
[data-bg-height='500']:before {
  height: 500px;
}
[data-bg-height='550']:before {
  height: 550px;
}
[data-bg-height='600']:before {
  height: 600px;
}

/*=====================================================*/
/* Main Banner */
/*=====================================================*/
// .site-content{
//   position: sticky;
//   top: 80px;
// }

.pointer-cursor {
  cursor: pointer;
}
.banner-slider {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1400px) {
    height: 270px;
  }
  @media only screen and (max-width: 1200px) {
    height: 240px;
  }

  @media only screen and (max-width: 950px) {
    height: 230px;
  }
  @media only screen and (max-width: 800px) {
    height: 200px;
  }
  @media only screen and (max-width: 720px) {
    height: 200px;
  }
  @media only screen and (max-width: 600px) {
    height: 210px;
  }
  @media only screen and (max-width: 480px) {
    height: 210px;
  }
  @media only screen and (max-width: 450px) {
    height: 210px;
  }
}

.banner-item {
  display: flex;
  align-items: center;
  // justify-content: center;
  justify-content: start;
  margin-left: 10%;
  position: relative;
  width: 100%;
  // margin: 0 10%;
}
.banner-content {
  text-align: left !important;
  color: $primary-color;
  width: 50%;
  text-align: center;
}
.swiper-slide .play-button {
  z-index: 999;
  width: 50%;
  text-align: center;
  .image-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0000003b;
    img {
      cursor: pointer;
    }
  }
}
.banner-item .title {
  font-family: $title-font;
  color: $primary-color;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}
.banner-item h6 {
  color: #333;
  font-size: 25px;
}

.banner-content .text-stroke-white {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  display: block;
}
.banner-content .font-large {
  font-size: 86px;
  line-height: 100px;
}

.banner-section .banner-content .btn-secondary {
  margin-top: 15px;
  background-color: $white-color;
  color: #000;
}

@media only screen and (max-width: 992px) {
  .banner-content {
    margin-left: 10%;
  }
}

/* Banner Slider Arrow */
.banner-section .swiper .swiper-button-prev {
  left: 3%;
}
.banner-section .swiper .swiper-button-next {
  right: 3%;
}
.banner-section .swiper .swiper-button-prev,
.banner-section .swiper .swiper-button-next {
  display: flex;
  width: 40px;
  height: 40px;
  text-align: center;
  opacity: 0.6;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
}
.banner-section .swiper .swiper-button-prev:after,
.banner-section .swiper .swiper-button-next:after {
  font-size: 16px;
}
.banner-section .swiper .swiper-button-prev:hover,
.banner-section .swiper .swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-next::after {
  color: #000 !important;
  content: '\f054' !important;
}

.swiper-button-prev::after {
  color: #000 !important;
  content: '\f053' !important;
}

.about-list li {
  color: #333;
  font-weight: 600;
}

/*-----------------------*/
/* Overlay Home Banner */
/*-----------------------*/
.overlay-home-banner {
  height: 750px;
  padding-top: 150px;
}
.banner-logo {
  display: inline-block;
  padding: 10px 20px;
  background: $primary-color;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  color: $white-color;
  font-size: 70px;
  line-height: 60px;
  position: relative;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 20px;
  text-align: center;
  border-radius: $radius-7;
}
.banner-logo:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 21px;
  left: -30px;
  border-top: 20px solid rgba(239, 49, 57, 0);
  border-right: 20px solid $primary-color;
  top: 15px;
}
.banner-list {
  padding: 0;
  margin: 30px 0 50px;
  list-style-type: none;
  text-align: center;
}
.banner-list li {
  display: inline-block;
}

.sidebar__widget-title {
  font-size: 20px;
}

.banner-list li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid #ffffff;
  padding: 0 15px;
}

/*------------------------------------------*/
/* RATING List */
/*------------------------------------------*/

.university-main-wraper {
  display: flex;
  align-items: start;
  background-color: #fff;
  position: relative;
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    flex-wrap: wrap;
    > * {
      flex: 0 0 50%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
    > * {
      flex: 0 0 50%;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media only screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.university-course-box {
  width: 40%;
  background-color: $primary-color;
  padding: 42px 10px 42px 10px;
  background: $primary-color;
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    padding: 35px 10px 40px 10px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    padding: 28px 10px 40px 10px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.university-main-wraper {
  position: relative;
  z-index: 5;
}
.university-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 5px;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.university-card-wrapper {
  text-align: center;
  padding: 0 20px;
}
.university-card-text {
  padding: 0 20px;
  p {
    font-weight: 900;
    color: #fff;
    margin-bottom: 15px;
    display: block;
    line-height: 1;
    font-size: 20px;
  }
  span {
    font-weight: 400;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 32px;
    line-height: 1.3;
    color: #fff;
  }
}

.university-card-icon {
  width: 90px;
  height: 90px;
  background: #f6f8fb;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  transition: 0.3s;
  margin: 0 auto 20px;
}
.digital-button {
  transition: all;
  transition-timing-function: ease-in-out;
  transition-duration: 400;
  &:hover {
    background-color: #ffb013;
    color: #000;
  }
}
.university-card-wrapper:hover .university-card-icon {
  transform: translateY(-10px);
}
.university-card-content {
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 1;
  }
  p {
    margin-bottom: 0;
    color: #575757 !important;
  }
}

.edu-six-btn {
  background-color: #fff;
  color: $primary-color;
  border-radius: 5px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
  @media (max-width: 800px) {
    line-height: 14px;
    font-size: 12px;
  }
}

/*------------------------------------------*/
/* Category List */
/*------------------------------------------*/

.categories-area {
  // background-color: $;
}
.categories-wrapper {
  // padding: 37px 20px 15px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: #000;
  padding: 37px 5px 15px 5px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(24, 44, 74, 0.05);
  border-radius: 10px;
}
.categiories-thumb {
  transition: 0.3s;
  svg {
    margin-bottom: 30px;
  }
}
.categories-wrapper:hover .categiories-thumb {
  transform: translateY(-10px);
}
.categories-content {
  h4 {
    margin-bottom: 5px;
  }
  p {
    font-weight: 600;
    margin-top: 10px;
    @media only screen and (min-width: 1600px) and (max-width: 1800px) {
      font-size: 15px;
    }
  }
}

.about__post-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.sidebar__tag {
  & a {
    display: inline-block;
    height: 36px;
    line-height: 38px;
    padding: 0 15px;
    background: #f6f8fb !important;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 4px;
    &:hover {
      color: #fff;
      background: $primary-color !important;
    }
  }
}

.about-sidebar .sidebar__tag a {
  height: fit-content !important;
  line-height: 30px;
}

.rule-content p {
  font-weight: 500;
  color: #000;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.membership-price {
  gap: 10px;
}

.know-us-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-color: #fff;
  margin-top: 30px;
  padding: 20px 0 10px;
}

.entry-description p {
  margin-bottom: 10px !important;
}

.know-us-text {
  min-height: 100px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.know-us-text {
  h3 {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik';
    padding-top: 15px;
    z-index: 3;
    position: relative;
  }
}
.know-us-wrapper {
  overflow: hidden;
  border-radius: 5px;
  img {
    transform: translateY(8px);
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  }
}
.know-us-wrapper:hover .know-us-better-thumb img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.know-us-tittle {
  color: #363636 !important;
}

.sidebar-widget-wrapper {
  margin-left: 20px;
  position: sticky;
  top: 100px;
  @media only screen and (max-width: 1200px) {
    margin-top: 30px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    margin-left: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-left: 0;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    margin-left: 0;
  }
  @media (max-width: 575px) {
    margin-left: 0;
  }
}
.event-detalis-area .sidebar-widget-wrapper {
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    margin-left: 0;
  }
}
.sidebar-widget {
  border: 1px solid $primary-color;
  border-radius: 5px;
  box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
  background-color: #fff;
  max-width: 510px;
  @media (max-width: 575px) {
    max-width: 100%;
  }
}
.sidebar-widget-inner {
  padding: 35px 30px 30px 30px;
  height: 385px;
}

.rc__post {
  align-items: center !important;
  gap: 15px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid $light-color;
  &:hover {
    background-color: rgba(0, 132, 61, 0.1);
  }
}

.first-letter-capital a {
  text-transform: lowercase !important;
}

.first-letter-capital a::first-letter {
  text-transform: uppercase !important;
}

.rc__post:last-child {
  border-bottom: none !important;
}

.rc__thumb.mr-20 img {
  width: 70px !important;
  aspect-ratio: '16:9';
  margin: 8px auto;
}

.rc__meta span {
  color: gray;
  font-size: 13px;
}

.rc__title a {
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.sidebar__widget {
  border: 1px solid #edeef2;
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
  background-color: #ffffff;
  @media (max-width: 575px) {
    padding: 30px 20px;
  }
}

.sidebar__category ul li:not(:last-child) {
  margin-bottom: 15px;
}

.sidebar__category ul li a {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding-left: 20px;
  position: relative;
}

.sidebar__category ul li a::after {
  position: absolute;
  content: '';
  left: 0;
  top: 9px;
  width: 6px;
  height: 6px;
  background: #adaeba;
  border-radius: 50%;
}

.sidebar__category ul li a:hover {
  color: $primary-color;
}

.sidebar__category ul li a:hover:after {
  background: $primary-color;
}

.sidebar__tag a {
  display: inline-block;
  height: 36px;
  line-height: 38px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  background: #fff;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.sidebar__tag a:hover {
  color: #fff;
  background: $primary-color;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.testimonial-area {
  background-color: #f6f6f6 !important;
  margin-top: 20px;
  cursor: grab;
}
.testimonial-items {
  background: #fff;
  padding: 20px 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2;
  border-radius: 5px;
  cursor: pointer;
}
.wow-title {
  border-bottom: 1px solid #00843d;
}

.testimonial-title {
  h4 {
    font-size: 16px;
    font-weight: 700;
  }
  span {
    font-size: 14px;
    margin-bottom: 2px;
    display: block;
  }
}
.testimoni-quotes {
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: -1;
    content: '';
  }
}
.testimonial-body {
  font-size: 18px;
  font-family: 'Rubik';
  font-weight: 400;
  color: #000;
}
.testimonial-icon {
  i {
    color: #000;
    font-size: 15px;
  }
}
.testimonial-pagination {
  margin-top: 50px;
  line-height: 1;
}

.-bg {
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    transition: 0.6s;
    object-position: center;
  }
}
// .browser-course-wrapper:hover .browser-course-bg img {
// 	transform: scale(1.05);
// }
.browser-course-wrapper {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.film {
  margin-right: 10px;
  font-size: 32px;
  width: 32px;
  height: 32px;
}
.browser-course-bg {
  position: absolute;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.course-height {
  min-height: 250px;
  padding: 30px 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  & .desktop {
    position: absolute;
    bottom: -65%;
  }
  @media (max-width: 1200px) {
    padding: 15px 30px;
    & span {
      height: 50px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      position: absolute;
      bottom: 15px;
    }
  }
}
.browser-course-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content:;
  gap: 30px;
  z-index: 1;

  span {
    margin-bottom: 10px;
    display: block;
    color: #000;
    font-weight: 700;
    @media (max-width: 575px) {
      left: 0;
      font-size: 14px;
    }
    @media (max-width: 1200px) {
      left: 0;
      font-size: 16px;
    }
  }
}
.browser-course-tittle {
  a {
    font-size: 24px;
    color: #000;
    font-weight: 700;
    line-height: 1.3;
    // margin-bottom: 30px;
    display: block;
    @media (max-width: 575px) {
      font-size: 20px;
    }
    @media (max-width: 1200px) {
      font-size: 22px;
    }
  }
}
ul.category-list {
  margin-bottom: 0;
  padding: 0 0 15px;
  height: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  list-style-type: none;
}
ul.category-list li {
  margin-bottom: 15px;
}
ul.category-list li a,
ul.category-list li span {
  display: inline-block;
  color: $white-color;
  text-decoration: none;
  font-size: 15px;
  transition: $transition;
}
ul.category-list li a:hover {
  color: $primary-color;
}

.detail-slider .slick-prev {
  left: 2%;
  z-index: 1;
}

.detail-slider .slick-next {
  right: 2%;
  z-index: 1;
}

.detail-slider .slick-dots {
  bottom: 2%;
  z-index: 1;
}

.image-gallery .image-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  padding: 100px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.image-gallery .image-modal .modal-inner {
  background: #ffffff;
  padding: 20px;
}

.browser-btn {
  width: 200px !important;
  color: $primary-color;
  text-align: center;
  a {
    color: #000;
  }
}

/*=====================================================*/
/* Home Page 3 */
/*=====================================================*/
.progress-info .progress-inner {
  display: flex;
  justify-content: space-between;
  color: $title-color;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.progress-info .progress {
  height: 10px;
}
.progress-info .progress-bar {
  height: 10px;
  background-color: $primary-color;
  border-radius: 5px;
}

/*=====================================================*/
/* About Us Page */
/*=====================================================*/
.year {
  font-size: 66px;
  margin-bottom: 0;
  opacity: 0.1;
  color: $primary-color;
}

@media only screen and (min-width: 768px) {
  .year {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: absolute;
    top: 50px;
    right: -30px;
    font-size: 96px;
  }
}

/*==========================================================*/
/* Team SIngle Page */
/*==========================================================*/
.team-single-page .team-info {
  padding: 0;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style-type: none;
}
.team-single-page .team-info li {
  width: 100%;
}
.team-single-page .team-info li label {
  min-width: 80px;
}
.team-single-page .team-info li .title {
  color: $title-color;
  margin-bottom: 0;
  display: flex;
  line-height: 1.5;
}

.team-single-page .personal-info {
  background: #f5f7fa;
  padding: 45px;
  border-radius: 12px;
}
.team-single-page .personal-info .title-area {
  display: flex;
  align-items: baseline;
}
.team-single-page .personal-info .title {
  margin-bottom: 0px;
  margin-right: 15px;
}
.team-single-page .personal-info .position {
  color: $primary-color;
}
.team-single-page .personal-info .team-social {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  list-style-type: none;
}
.team-single-page .personal-info .team-social li a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white-color;
  background-color: $primary-color;
  font-size: 15px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 17px;
  border: 2px solid $primary-color;
  transition: $transition-3;
}
.team-single-page .personal-info .team-social li a:hover {
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $white-color;
}

/*==========================================================*/
/* MINI SLIDER NEWS */
/*==========================================================*/

.mini-slider-news {
  background-color: #f6f8fb;
  box-shadow: 0px 4px 11.199999809265137px 0px #00000033;
}

.mini-slider-news .marquee {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
}

.marquee:hover {
  background-color: $primary-color;
  color: #fff;
  svg path {
    fill: #fff;
  }
}

/*==========================================================*/
/* QL EDITOR */
/*==========================================================*/
.ql-align-justify {
  font-family: 'Rubik';
  text-align: justify;
}
.ql-align-right {
  font-family: 'Rubik';
  text-align: right;
}
.entry-description {
  * {
    color: #000 !important;
    background-color: transparent !important;
  }
  p a {
    color: #00843d !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

/*==========================================================*/
/* FOOTER */
/*==========================================================*/

.footer-desc {
  color: #363636;
  font-weight: 300;
  margin-top: 10px;
  font-size: 16px;
}

.capitalize {
  text-transform: capitalize !important;
}

.footer-title {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.main-slider {
  border-radius: 8px;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {
  /*----------------------------*/
  /* Home 1 : Main Banner */
  /*----------------------------*/
  .banner-content {
    max-width: 670px;
    padding: 0 15px;
  }
  .banner-item .title {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  /* Home 2 : Main Banner */
  .banner-content .font-large {
    font-size: 66px;
    line-height: 76px;
  }
  .main-slider {
    // background-position: 60% center !important;
  }
}

@media only screen and (max-width: 768px) {
  /*----------------------------*/
  /* Home 1 : Main Banner */
  /*----------------------------*/

  .banner-item {
    display: none;
  }
  .play-button {
    display: flex;
    justify-content: center;
  }
  /* Home 2 : Main Banner */
  .banner-content .font-large {
    font-size: 52px;
    line-height: 60px;
  }

  /* Home 3 : Main Banner */
  .overlay-home-banner {
    height: 500px;
    padding: 50px 0;
  }
  .banner-logo {
    width: auto;
    font-size: 50px;
    line-height: 50px;
  }
  .main-slider {
    // background-position: center center !important;
  }
}
