@import "../../assets/scss/variable.scss";

@media only screen and (max-width:576px){
    .pagination{
        display: block;
        text-align: center;
        .loader-page{
            padding-bottom: calc(0.375rem + 1px);
        }
    }
}

.pagination {
    border-radius: 0;
    padding: 2px 15px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    div {
      padding-left: 0;
      padding-right: 0;
    }
  
    .mainbar-link {
      font-size: 12px;
      display: flex;
      align-items: center;
      font-weight: 600;
      text-decoration: none;
    }
    .form-group {
      padding-left: 0;
      padding-right: 0;
      .col-form-label {
        text-align: right;
      }
    }
    .loader-page {
      display: inline-block;
      background-color: transparent;
      margin-bottom: 0;
      padding: 0;
      margin-top: 6px;
  
      .loader-page-item {
        font-size: 1.2rem;
        display: inline-block;
        border-radius: 4px;
        // border: 1px solid $primary-color;
        text-align: center;
        span {
          cursor: pointer;
          line-height: 12px;
          padding: 3px 10px;
        }
        &.active {
          color: #fff;
          background-color: $primary-color;
        }
      }
    }
  }