@import '../../assets/scss/variable.scss';
.event_date_inner {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}

.competition .event_date_inner{
	vertical-align: top;
}
.event_date {
	width: 120px;
	height: 120px;
	// background: #f8f8f9;
	text-align: center;
	display: table;
	border-radius: 5px;
	padding: 0 10px;
	@media only screen and (min-width: 992px) and (max-width: 1199px) {
		width: 100px;
		height: 100px;
	}
	@media only screen and (min-width: 1200px) and (max-width: 1399px) {
		width: 100px;
		height: 100px;
	}
	& .img{
		height: 80px;
		width: 100px;
		object-fit: contain;
	}
}
.aduence-thumb {
	margin-right: 32px;
	img {
		box-shadow: 0px 0px 10px rgba(17, 32, 80, 0.14);
		border-radius: 50%;
		margin-right: -16px;
		border: 4px solid #fff;
	}
}
.event_date_inner {
	h4 {
		font-size: 24px;
		font-weight: 900;
		color: #000;
		font-family: "Nunito Sans", sans-serif;
	}
	span {
		color: #171717;
	}
}
.event_info h3 {
	font-size: 20px;
	margin: auto 0;
}

.event-time {
	i {
		line-height: 1;
		margin-right: 10px;
	}
}
.event-location {
	i {
		margin-right: 10px;
	}
}
.event-detalis {
	flex-wrap: wrap;
    color: #333333;
}
.event-aduence {
	flex-wrap: wrap;
}
.event_info {
	flex-grow: 1;
}
.get-btn {
	gap: 8px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 5px;
    padding: 3px 8px;
    transition: all;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;

    &:hover{
        background-color: $primary-color;
        color: #ffff;
    }
}
.single-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	border: 1px solid #eeeeee;
	padding: 0px 5ssspx;
	background-color: #fff;
	border-radius: 3px;
	transition-duration: 300ms;
	@media only screen and (min-width: 992px) and (max-width: 1199px) {
		gap: 20px;
	}
	@media only screen and (min-width: 1200px) and (max-width: 1399px) {
		gap: 20px;
	}
	@media only screen and (max-width: 600px){
        flex-direction: column;
    }
	&:hover {
		box-shadow: 0px 30px 40px rgba(28, 51, 84, 0.07);
	}
}

.single-item:hover .event_info h3{
	color: $primary-color;
}

.img-wrapper{
	width: 300px;
	min-width: 200px;
	max-width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.single-item-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.competition .single-item{
    align-items: start !important;
}

.single-item .event_info {
    text-align: left;
	
}
// event-meta
.event-meta-thumb {
	display: flex;
	align-items: center;
}
.event-wrapper {
	padding: 30px;
	background: #f8f8f9;
	height: 110px;
	width: 100%;
	border-radius: 4px;
}
.event-select .nice-select {
	&::after {
		height: 8px;
		width: 8px;
		right: 20px;
		color: #000;
	}
	height: 60px;
	width: 100%;
	line-height: 60px;
	position: relative;
	margin-top: -5px;
	border: 0;
	padding: 0 20px;
	span {
		font-size: 16px;
		color: #000;
		font-weight: 600;
	}
}
.nice-select.open .list {
	width: 100%;
	color: #000;
	font-size: 16px;
}
// event-inner
.find-event-info {
	h4 {
		margin-bottom: 35px;
	}
}
.find-event-input {
	i {
		color: $primary-color;
	}
}
.find-event-wrapper {
	position: relative;
}
.find-event-input {
	i {
		position: absolute;
		top: 54%;
		right: 20px;
		transform: translateY(-50%);
	}
}
.find-event-input input {
	width: 100%;
	height: 55px;
	padding: 0 20px;
	outline: none;
	border: 0;
	background: #fff;
	border-radius: 4px;
}
.event-sidebar {
	background-color: #f8f8f9;
	padding: 30px 30px;
	border-radius: 5px;
}
// event-details
.event-contact-info {
	h2 {
		margin-bottom: 30px;
		@media only screen and (min-width: 768px) and (max-width: 991px) {
			font-size: 32px;
		}
		@media only screen and (min-width: 576px) and (max-width: 767px) {
			font-size: 24px;
		}
		@media (max-width: 575px) {
			font-size: 24px;
		}
	}
}
.introduction-info {
	h4 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}
.introduction-info {
	p {
		margin-bottom: 25px;
	}
}
.even-point-tittle {
	h4 {
		margin-bottom: 15px;
		font-size: 18px;
	}
}
.event-speaker-info {
	h4 {
		font-size: 20px;
		margin-bottom: 35px;
	}
}
.event-speaker-wrapper {
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	border: 1px solid $primary-color;
	background-color: #fff;
	padding: 28px 29px 28px 29px;
	border-radius: 5px;
	position: relative;
}
.event-speaker-content {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ffffff;
	padding: 13px 20px 19px 20px;
	border-radius: 3px;
	min-width: 240px;
	span {
		font-size: 16px;
		font-weight: 700;
		color: #000;
		display: block;
		margin-bottom: 5px;
	}
	p {
		margin-bottom: 0px;
		line-height: 1;
	}
}
.event-price-info {
	display: flex;
	justify-content: space-between;
}
.event-price {
	display: flex;
}
.event-information-wrapper {
	padding: 30px 30px 30px 30px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	background-color: white;
	border-radius: 5px;
	border: 1px solid $primary-color;
	@media (max-width: 575px) {
		padding: 30px 20px 30px 20px;
	}
}
.event-information-list {
	padding: 30px 0 30px 0px;
	ul {
		li {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $primary-color;
			padding: 10px 0px 10px 0px;
			flex-wrap: wrap;
			&:last-child {
				border: none;
				padding-bottom: 5px;
			}
		}
		i {
			line-height: 0;

			margin-right: 15px;
		}
	}
}
.information-list {
	display: flex;
	align-items: center;
	&:first-child {
		span {
			font-weight: 600;
		}
	}
}
.old-price {
	margin-left: 10px;
}
.event-price {
	span {
		font-size: 28px;
		font-weight: 900;
		color: #000;
	}
}
.event-ticket-cost {
	span {
		font-weight: 600;
	}
}
