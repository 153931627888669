@import '../../assets/scss/variable.scss';

.membership-tittle {
	p {
		color: #000;
		margin-bottom: 50px;
	}
}

.membership-link a{
	color: #2467ec;
	&:hover{
		color: #043597;
	}
}
.pricing-tab .nav-tabs {
	border: none;
}
.pricing-tab .nav-tabs .nav-link {
	margin-bottom: 0;
	border: 0;
}
.pricing-tab .nav .nav-item .nav-link {
	padding: 7px 60px;
	background: transparent;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	font-weight: 600;
	text-transform: capitalize;
	color: #000;
	position: relative;
	outline: none;
	font-size: 16px;
	border: none;
	z-index: 2;
}
.pricing-tab .nav .nav-item:last-child .nav-link {
	padding-right: 0;
}
.pricing-tab .nav .nav-item:first-child .nav-link {
	padding-left: 0;
}
.pricing-tab .nav .nav-item {
	padding-left: 0px;
}
.pricing-tab .nav .nav-item .nav-link.active {
	color: $primary-color;
}
.pricing-tab .nav .nav-item .nav-link.active {
	&::after {
		right: 10px;
	}
}
.pricing-tab .nav .nav-item .nav-link {
	&::after {
		position: absolute;
		content: "";
		right: -32px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 22px;
		height: 22px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background: $primary-color;
		z-index: 1;
	}
}
.pricing-tab .nav .nav-item:last-child .nav-link {
	&::after {
		display: none;
	}
}
.pricing-tab .nav .nav-item .nav-link::before {
	position: absolute;
	content: "";
	right: 0px;
	top: 50%;
	-webkit-transform: translateY(-50%) translateX(50%);
	-moz-transform: translateY(-50%) translateX(50%);
	-ms-transform: translateY(-50%) translateX(50%);
	transform: translateY(-50%) translateX(50%);
	background: #f5f6f9;
	width: 80px;
	height: 40px;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
	border-radius: 20px;
	border: 1px solid $primary-color;
}
.pricing-tab .nav .nav-item:last-child .nav-link::before {
	display: none;
}
.membership-box {
	padding: 40px 40px;
	box-shadow: 0px 10px 40px rgba(12, 29, 74, 0.08);
	border-radius: 8px;
	background: #fff;
	border: 1px solid #b4b4b4;
	overflow: hidden;
	position: relative;
	height: 380px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: 575px) {
		padding: 40px 30px;
	}
	@media only screen and (min-width: 1200px) and (max-width: 1399px) {
		padding: 40px 30px;
	}
}
.membership-info {
	h4 {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 15px;
		color: #333;
	}
	span {
		font-size: 24px;
		min-height: 150px;
		font-weight: 900;
		color: #000;


	}
}
.membership-price {
    font-size: 14px;
        line-height: 30px;
        text-align: left;
        font-family: "Rubik";
	p {
		font-size: 14px;
        line-height: 30px;
		color: #000;
		margin: 20px 0;
	}
	span{
		align-items: center;
		display: flex
	};
}

.row-reverse{
	@media only screen and (max-width:1200px) {
		display: flex;
		flex-direction: column-reverse;
		gap: 30px;
	}
}


.rc__title div{
	color: #000;
	
  }

  .rc__post{
	align-items: center !important;
	gap: 15px;
	padding-top: 20px;
	padding-left: 10px;
	padding-bottom: 15px;
	border-bottom: 1px solid $light-color;
	&:hover{
	  background-color: rgba(0, 132, 61, .1);
	  
	}
  }
  
  
  .active_post{
	background-color: rgba(0, 132, 61, 1)!important;
	& .rc__meta span, .rc__title div{
	  color: #fff;
	}
  }


.first-letter-capitalize {
	text-transform: lowercase !important;
}

.first-letter-capitalize::first-letter {
	text-transform: capitalize !important;
}

.membership-list {
	padding: 15px 0;
	ul {
		li {
			margin-bottom: 5px;
			i {
				color: $primary-color;
				margin-right: 15px;
			}
		}
	}
}
.Popular-plan {
	position: absolute;
	background: $primary-color;
	top: -30px;
	right: -84px;
	font-size: 14px;
	font-weight: 600;
	color: #000;
	text-align: center;
	padding: 62px 72px 6px;
	transform: rotate(48deg);
}
.Popular-plan {
	span {
		width: 70px;
		display: inline-block;
		font-size: 14px;
		line-height: 16px;
		font-weight: 600;
		font-family: "Raleway", sans-serif;
	}
}

.membership-btn {
	height: 55px;
	background: $primary-color;
	display: block;
	line-height: 55px;
	text-align: center;
	border-radius: 4px;
	color: white;
    border: 1px solid white;
	padding: 0 30px;
	transition-duration: 400ms;
    transition-timing-function: ease-in-out;
    transition-property: all;
	&:hover {
		background-color: white;
		color: $primary-color;
        border: 1px solid $primary-color;
	}
}
